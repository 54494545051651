<template>
  <div class="AdminUserStatistics" v-if="$isAdmin">
    <div class="AdminUserStatisticsUser" dpadding>
      <UserAvatar :user="user" size="82px" />
      <Spacer num=".7" />
      <p class="AdminUserStatisticsName">{{ user.name }}</p>
      <p class="AdminUserStatisticsEmail">{{ user.email }}</p>
    </div>
    <Spacer num="1" />
    <div class="AdminUserStatisticsMenu" prevent>
      <Spacer num="1" bodybg border-bottom border-top />
      <LargeButton :label="$locale['date_from']" icon="false" :rightDesc="`<span>${Go.date_locale(statistics.startDate)}</span>`" />
      <LargeButton :label="$locale['date_to']" icon="false" :rightDesc="`<span>${Go.date_locale(statistics.endDate)}</span>`" />
      <Spacer num="1" bodybg border-bottom />
      <LargeButton :label="$locale['total_posts']" icon="false" :rightDesc="`<strong>${Go.number_format(user.totalPosts, 0)}</strong>`" />
      <LargeButton
        :label="$locale['total_subscriptions']"
        icon="false"
        :rightDesc="`<strong>${Go.number_format(user.totalSubscriptions, 0)}</strong>`"
      />
      <LargeButton :label="$locale['total_credits']" icon="false" :rightDesc="`<strong>${Go.number_format(user.totalAmountCredits, 0)}</strong>`" />
      <Spacer num="1" bodybg border-bottom />
      <LargeButton
        :label="$locale['total_sales_by_subscriptions']"
        icon="false"
        :rightDesc="`<strong>$ ${Go.number_format(user.totalAmountSubscriptions, 2)}</strong>`"
      />
      <LargeButton
        :label="$locale['total_sales_by_credits']"
        icon="false"
        :rightDesc="`<strong>$ ${Go.number_format(user.totalCreditsUSD, 2)}</strong>`"
      />
      <LargeButton
        :label="`<strong>${$locale['total_sales']}</strong>`"
        icon="false"
        :rightDesc="`<strong>$ ${Go.number_format(user.totalSalesUSD, 2)}</strong>`"
      />
      <LargeButton
        :label="`<strong>${$locale['total_sales_net']}</strong>`"
        icon="false"
        :rightDesc="`<strong>$ ${Go.number_format(user.totalSalesUSDNet, 2)}</strong>`"
      />
      <Spacer num="1" bodybg border-bottom />
      <LargeButton
        align="top"
        icon="false"
        :label="`<strong>${$locale['creator_earnings']}</strong>`"
        :desc="`<div>${$locale['commission']} (${user.commissionPercent}%)</div>`"
        :rightDesc="
          `<strong>$ ${Go.number_format(Go.getPercentValue(user.commissionPercent, user.totalSalesUSDNet), 2)}</strong>
          <div small>${$locale['usd']}</div>`
        "
      />
    </div>
    <UserStatisticsResumeMenuAdmin :user="user" />
    <Spacer v-if="$isMobile" num="4" />
  </div>
</template>

<script>
export default {
  props: ["user", "modal", "statistics"],
  components: {
    UserStatisticsResumeMenuAdmin: () => import("./UserStatisticsResumeMenuAdmin.vue"),
  },
};
</script>

<style lang="scss">
.AdminUserStatistics {
  .AdminUserStatisticsUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .AdminUserStatisticsName {
    font-size: 120%;
  }
}
</style>
